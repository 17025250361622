import {cva} from 'class-variance-authority';
import React from 'react';

import {twMerge} from '@/stylesheets/twMerge';
import Image, {type ImageProps} from '@/components/base/elements/Image/Image';
import {TwoColumn} from '@/components/base/layouts/TwoColumn/TwoColumn';

const col1Styles = cva('', {
  variants: {
    visualPosition: {
      left: '',
      right: '',
    },
    verticalAlign: {
      top: '',
      center: '',
    },
  },
  compoundVariants: [
    {
      visualPosition: 'right',
      className: 'sm:col-span-6',
    },
    {
      visualPosition: 'right',
      verticalAlign: 'center',
      className: 'place-content-center',
    },
  ],
});

const col2Styles = cva('', {
  variants: {
    visualPosition: {
      left: '',
      right: 'order-first md:order-last',
    },
    verticalAlign: {
      top: '',
      center: '',
    },
  },
  compoundVariants: [
    {
      visualPosition: 'left',
      className: 'sm:col-span-6',
    },
    {
      visualPosition: 'left',
      verticalAlign: 'center',
      className: 'place-content-center',
    },
  ],
});

export interface SideBySideProps {
  children: React.ReactNode;
  componentName?: string;
  gutter?: 'left' | 'right';
  image?: ImageProps;
  mode?: 'light' | 'dark';
  visualPosition?: 'left' | 'right';
  verticalAlign?: 'top' | 'center';
  className?: string;
}

export default function SideBySide({
  children,
  componentName = 'side-by-side',
  gutter,
  image,
  mode = 'light',
  visualPosition = 'left',
  verticalAlign = 'top',
  className,
}: SideBySideProps) {
  const {
    height,
    src,
    width,
    alt,
    className: imageClassName,
    sources,
  } = image || {};

  function getContent(isImageFirst: boolean) {
    return isImageFirst && src ? (
      <Image
        {...image}
        src={src}
        width={width}
        height={height}
        alt={alt}
        sources={sources ?? []}
        className={twMerge('mx-auto', imageClassName)}
      />
    ) : (
      <>
        {React.Children.map(children, (child) => {
          const props = {mode: mode};
          return React.isValidElement(child)
            ? React.cloneElement(child, props)
            : child;
        })}
      </>
    );
  }

  return (
    <TwoColumn componentName={componentName} className={className}>
      <TwoColumn.Col1
        className={col1Styles({
          visualPosition,
          verticalAlign,
        })}
        withGutter={gutter ? gutter === 'left' : visualPosition === 'right'}
      >
        {getContent(visualPosition === 'left')}
      </TwoColumn.Col1>
      <TwoColumn.Col2
        className={col2Styles({
          visualPosition,
          verticalAlign,
        })}
        withGutter={gutter ? gutter === 'right' : visualPosition === 'left'}
      >
        {getContent(visualPosition === 'right')}
      </TwoColumn.Col2>
    </TwoColumn>
  );
}
